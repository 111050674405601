import styles from "./report.module.css";

export const ReportDetails = (props) => {
  const { virus_subtypes, coverages, mutations_resist, tropism } = props.data;
  const renderData = (items) => {
    if (items) {
      const limitedItems = items.slice(0, 2);
      const hasData = limitedItems.some((subArray) =>
        Object.values(subArray).some((subData) => subData.length > 0)
      );

      return (
        <div style={{ maxWidth: "920px" }}>
          <span className={styles.alert}>
            {limitedItems.map((subArray, index) =>
              Object.entries(subArray).map(
                ([subKey, subData]) =>
                  subData.length > 0 &&
                  `${subData.join(", ")}${
                    index !== limitedItems.length - 1 ? ", " : ""
                  }`
              )
            )}
          </span>
          {hasData ? null : <span>Наличие мутаций не удалось определить</span>}
        </div>
      );
    } else {
      return <span>Наличие мутаций не удалось определить</span>;
    }
  };
  return (
    <div className={styles.detailsWrapper}>
      <div className={styles.detailsMain}>
        <table className={styles.mainTable}>
          <thead>
            <tr>
              <th className={styles.datailsBorderCell}>
                Результаты секвенирования
              </th>
              <th className={styles.datailsBorderCell}>% покрытия</th>
              <th>Наличие мутаций устойчивости к АРВТ</th>
            </tr>
          </thead>
          <tbody className={styles.datailsTableBody}>
            <tr>
              <td className={styles.datailsBorderCell}>
                Покрытие гена протеазы
              </td>
              <td className={styles.datailsBorderCell}>
                {coverages.PR_percent}%
              </td>
              <td>
                {mutations_resist?.PR
                  ? renderData(mutations_resist?.PR)
                  : "Не удалось определить"}
              </td>
            </tr>
            <tr>
              <td className={styles.datailsBorderCell}>
                Покрытие гена обратной транскриптазы
              </td>
              <td className={styles.datailsBorderCell}>
                {coverages.RT_percent}%
              </td>
              <td>
                {mutations_resist?.RT
                  ? renderData(mutations_resist?.RT)
                  : "Не удалось определить"}
              </td>
            </tr>
            <tr>
              <td className={styles.datailsBorderCell}>
                Покрытие гена интегразы
              </td>
              <td className={styles.datailsBorderCell}>
                {coverages.IN_percent}%
              </td>
              <td>
                {mutations_resist?.IN
                  ? renderData(mutations_resist?.IN)
                  : "Не удалось определить"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.datailsRight}>
        <div className={styles.subtype}>
          <div className={styles.subtypeTitle}>Субтип</div>
          <div className={styles.subtypeText}>{virus_subtypes.blast}</div>
        </div>
        <div className={styles.subtype}>
          <div className={styles.subtypeTitle}>Тропизм</div>
          <div className={styles.subtypeText}>
            {tropism ? tropism : "Не удалось определить"}
          </div>
        </div>
      </div>
    </div>
  );
};
