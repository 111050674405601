import { INITIAL_STATE } from '../../utils/initial-state';
import {
    CREATE_PROJECT_REQUEST,
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILED,
    DELETE_CURRENT_PROJECT,
    UPLOAD_FILES_START,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAILED,
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
    GET_PROJECTS_FAILED,
    UPDATE_PROJECT_STATUS,
    CHANGE_CREATE_PROJECT_ID,
    RESET_UPLOAD_FILES_STATUS,
    ADD_DEMO_PROJECT,
} from '../actions/currentProjects';

export const projectListReducer = (state = INITIAL_STATE.projectList, action) => {
    switch (action.type) {
        case GET_PROJECTS_REQUEST:
            return {
                ...state,
                getProjectsRequest: true,
            };
        case GET_PROJECTS_SUCCESS:
            const sortedItems = action.items.sort((a, b) => b.id - a.id);
            return {
                ...state,
                getProjectsRequest: false,
                getProjectsFailed: false,
                getProjectsSuccess: true,
                items: sortedItems,
            };
        case GET_PROJECTS_FAILED:
            return {
                ...state,
                getProjectsRequest: false,
                getProjectsFailed: true,
                getProjectsSuccess: false,
            };
        case CREATE_PROJECT_REQUEST:
            return {
                ...state,
                createProjectRequest: true,
                createProjectId: 0,
                filesUploadRequest: false,
                filesUploadSuccess: false,
                filesUploadFailed: false,
            };
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                createProjectSuccess: true,
                createProjectRequest: false,
                createProjectId: action.payload.id,
                items: [
                    action.payload,
                    ...state.items
                ],
            };
        case CHANGE_CREATE_PROJECT_ID:
            return {
                ...state,
                createProjectId: action.payload,
            }
        case CREATE_PROJECT_FAILED:
            return {
                ...state,
                createProjectFailed: true,
                createProjectRequest: false,
            };
        case DELETE_CURRENT_PROJECT:
            return {
                ...state,
                items: state.items.filter((item) => item.id !== action.id),
            };
        case UPLOAD_FILES_START:
            return {
                ...state,
                filesUploadRequest: true,
            };
        case UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                items: state.items.map(project =>
                    project.id === action.id
                        ? { ...project, status: "AN" }
                        : project
                ),
                filesUploadSuccess: true,
                filesUploadRequest: false,
                createProjectId: 0,
            };
        case UPLOAD_FILES_FAILED:
            return {
                ...state,
                filesUploadFailed: true,
                filesUploadRequest: false,
            };
        case RESET_UPLOAD_FILES_STATUS:
            return {
                ...state,
                filesUploadSuccess: false,
                filesUploadFailed: false,
                filesUploadRequest: false,
            };
        case UPDATE_PROJECT_STATUS:
            return {
                ...state,
                items: state.items.map((item) =>
                    item.id === action.payload.projectId
                        ? { ...item, status: action.payload.status, status_text: action.payload.statusText, percent: action.payload.percent }
                        : item
                )
            };
            case ADD_DEMO_PROJECT:
                return {
                    ...state,
                    createProjectSuccess: true,
                    createProjectRequest: false,
                    createProjectId: 0,
                    items: [
                        {
                            id: 0,
                            title: "Демо-проект",
                            created_date: new Date().toISOString().split('T')[0],
                            status: "RY",
                            status_text: "demo",
                        },
                        ...state.items
                    ],
                };
        default:
            return state;
    }
};