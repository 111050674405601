import React, { useState } from "react";
import styles from "./header.module.css";
import { Link } from "react-router-dom";
import Modal from "../Modals/Modal";
import { About } from "../About/About";
import { ABOUT_URL } from "../../utils/api-routes";
import Loader from "../ui/Loader/Loader";
import { ADD_DEMO_PROJECT } from "../../services/actions/currentProjects";
import { useDispatch } from "react-redux";

export const HeaderNav = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [aboutData, setAboutData] = useState({
    loading: false,
    loaded: false,
    error: false,
    data: [],
  });
  const handleDemoClick = () => {
    dispatch({
          type: ADD_DEMO_PROJECT,
        });
  };
  const handleNavClick = (evt) => {
    setModalContent(evt.target.title);
    setAboutData({
      loading: true,
      loaded: false,
      error: false,
      data: [],
    });
    const fetchData = async () => {
      try {
        const response = await fetch(ABOUT_URL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        setAboutData({
          loading: false,
          loaded: true,
          error: false,
          data: data,
        });
      } catch (error) {
        console.error("Error while fetching data:", error);
        setAboutData({
          loaded: true,
          error: true,
          loading: false,
          message: error.message,
          data: [],
        });
      }
    };

    fetchData();
    setModalOpen(true);
  };
  return (
    <>
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <li>
            <Link
              className={`${styles.link} link-default`}
              to="/docs/help"
              title="Help"
              target="_blank"
            >
              Помощь
            </Link>
          </li>
          <li>
            <Link
              className={`${styles.link} link-default`}
              onClick={handleNavClick}
              title="About"
            >
              О программе
            </Link>
          </li>
          <li>
            <Link
              className={`${styles.link} link-default`}
              onClick={handleDemoClick}
              title="Demo"
            >
              Демо-проект
            </Link>
          </li>
        </ul>
      </nav>
      {isModalOpen && modalContent === "About" && aboutData.loaded && (
        <Modal type="info" onClose={() => setModalOpen(false)}>
          <About data={aboutData.data} message={aboutData.message} />
        </Modal>
      )}
      {aboutData.loading && <Loader />}
    </>
  );
};
