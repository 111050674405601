import { ACCORDEON_STATUS_LABEL } from "../../utils/constants";
import styles from "./report.module.css";
import "./report.module.css";

export const AccordeonStatusLabel = (props) => {
  const level = props.level;
  const labelText = ACCORDEON_STATUS_LABEL[level];
  const styleModifier =
    level === 5
      ? styles.levelFive
      : level === 4
      ? styles.levelFour
      : level === 3
      ? styles.levelThree
      : level === 2
      ? styles.levelTwo
      : "";
  const { type } = props;

  return (
    <>
      {type !== "IN" ? (
        <div className={`${styles.accordeonStatusLabel} ${styleModifier}`}>
          <span>{labelText}</span>
        </div>
      ) : (
        <div
          className={`${styles.accordeonStatusLabel} ${
            level === 6
              ? styles.levelFive
              : level === 7
              ? styles.lightGreen
              : styles.lightGrey
          }`}
        ></div>
      )}
    </>
  );
};
