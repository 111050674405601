import styles from "./report.module.css";
import { AccordeonBarMarker } from "./AccordeonBarMarker";

export const AccordeonBar = (props) => {
  const { type, mutations, tropism } = props;
  const styleModifier =
    type === "PR"
      ? styles.accordeonBarPR
      : type === "PI"
      ? styles.accordeonBarPI
      : type === "NNRTI"
      ? styles.accordeonBarNNRTI
      : "";
  const getOffset = (mutation) => {
    const matches = mutation.match(/\d+/);
    if (matches) {
      return Math.round((parseInt(matches[0], 10) * 100) / 250);
    }
    return 0;
  };
  return (
    <>
      {type !== "IN" ? (
        <div className={`${styles.accordeonBar} ${styleModifier}`}>
          {props.mutations &&
            props.mutations.length > 0 &&
            mutations.map((mutation, index) => (
              <span
                key={index}
                className={styles.accordeonBarMutations}
                style={{
                  marginLeft: getOffset(mutation[0]) + "%",
                  position: "absolute",
                }}
              >
                <AccordeonBarMarker mutation={mutation[0]} />
              </span>
            ))}
          {!props.mutations && (
            <span className={styles.accordeonBarMessage}>
              Не удалось определить
            </span>
          )}
        </div>
      ) : (
        <div className={`${styles.accordeonBar} ${styleModifier}`}>
          <span className={styles.accordeonBarMessage}>
            {tropism ? tropism : "Не удалось определить"}
          </span>
        </div>
      )}
    </>
  );
};
