import React from "react";
import styles from "./about.module.css";

export const About = (props) => {
  const { data, message } = props;
  return (
    <>
      <div className={styles.aboutContainer}>
        <h2 className={styles.mainTitle}>{data.title}</h2>
        <p className={styles.otherTitle}>
          Версия: <span className={styles.baseText}>{data.version}</span>
        </p>
        <p className={styles.otherTitle}>
          Описание: <br />{" "}
          <span className={styles.baseText}>{data.description}</span>
        </p>
        <p className={styles.otherTitle}>Основные функции:</p>
        <ul className={styles.featuresList}>
          {data.features.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p className={styles.otherTitle}>
          Разработчик:{" "}
          <span className={styles.baseText}>{data.developer}</span>
        </p>
        <p className={styles.otherTitle}>
          Лицензия: <br />{" "}
          <span className={styles.baseText}>{data.license}</span>
        </p>
      </div>
    </>
  );
};
