import styles from "./report.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LogarithmicScale,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const ReportGraph = (props) => {
  function extractAndSort(inputObject) {
    const inputArray = Object.values(inputObject);

    inputArray.sort((a, b) => a[0] - b[0]);

    const resultArray = inputArray.map((item) => item[1]);

    while (resultArray.length < 10000) {
      resultArray.push(0);
    }

    return resultArray.slice(0, 10000);
  }

  function getMaxYValue(inputObject) {
    const inputArray = Object.values(inputObject);
    let max = 0;
    inputArray.forEach((item) => {
      if (item[1] > max) {
        max = item[1];
      }
    });
    return max;
  }

  console.log("Max value ", getMaxYValue(props.data));

  const lineChartData = {
    labels: Array.from({ length: 10000 }, (_, i) => i),
    datasets: [
      {
        data: extractAndSort(props.data),
        borderColor: "#F79032",
        backgroundColor: "rgba(247, 144, 50, 0.13)",
        fill: true,
        lineTension: 0.5,
        borderWidth: 2, // Line width
        pointRadius: 0, // Hide points
        pointHoverRadius: 0, // Hide points on hover
      },
    ],
  };
  return (
    <div className={styles.graphWrapper}>
      <div className={styles.prrtin}></div>
      <div className={styles.envgp}></div>
      <Line
        type="line"
        options={{
          plugins: {
            legend: {
              display: false, // Отключаем отображение легенды
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              ticks: {
                stepSize: 2000, // шаг между отметками по оси X
                maxTicksLimit: 5,
              },
              // make sure the entire scale is displayed
              min: 0,
              max: 10000,
            },
            y: {
              type: "logarithmic",
              position: "left",
              ticks: {
                maxTicksLimit: 6,
                callback: function (value, index, ticks) {
                  return "10" + "⁰¹²³⁴⁵⁶⁷⁸⁹"[Math.floor(Math.log10(value))];
                },
              },
              min: 1,
            },
          },
        }}
        data={lineChartData}
      />
    </div>
  );
};
